







































































import {
  Component, Vue,
} from 'vue-property-decorator';

import AllianzDepositOptionsViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/allianz-deposit-options-view-model';

@Component({
  name: 'DepositOptions',
  components: {
    DepositOption: () => import('@/vue-app/components/allianz-dashboard/deposits/DepositOption.vue'),
  },
})
export default class DepositOptions extends Vue {
  allianz_deposit_options_view_model = Vue.observable(
    new AllianzDepositOptionsViewModel(this),
  );

  depositOptionSelected() {
    this.$emit('depositOptionSelected', this.allianz_deposit_options_view_model.option_selected);
  }

  created() {
    this.allianz_deposit_options_view_model.initialize();
  }
}

