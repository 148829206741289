import Vue from 'vue';
import TYPES from '@/types';

// Application
import { GetAllianzAccountQuery } from '@/modules/my-investment/allianz/allianz-account/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzDepositOptionsViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  private readonly view: Vue;

  private readonly customer_id = sessionStorage.getItem('user_id');

  i18n_namespace = 'components.allianz-dashboard.deposits';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  is_disabled = true;

  option_selected = 0;

  policy_number = '';

  deposit_options = [
    {
      icon_src: 'recurring.svg',
      icon_active_src: 'recurring_white.svg',
      title: this.translate('recurring').toString(),
      description: this.translate('recurring_detail').toString(),
      option_name: 'recurring_contribution',
      is_active: false,
    },
    {
      icon_src: 'flexible.svg',
      icon_active_src: 'flexible_white.svg',
      title: this.translate('flexible').toString(),
      description: this.translate('flexible_detail').toString(),
      option_name: 'flexible_contribution',
      is_active: false,
    },
  ];

  public constructor(view: Vue) {
    this.view = view;
  }

  setActiveOption = (option_name: string) => {
    this.is_disabled = false;
    this.deposit_options.forEach((deposit_option, index) => {
      const selected = option_name === deposit_option.option_name;
      this.deposit_options[index].is_active = selected;
      if (selected) {
        this.option_selected = index;
      }
    });
  }

  initialize = async () => {
    const allianz_account = await this.get_allianz_account_query.execute(
      { customer_id: this.customer_id },
    );
    this.policy_number = allianz_account.policy_number_issued;
  }

  cancel = () => {
    this.view.$emit('endProcess');
  }
}
